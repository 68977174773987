import { io } from 'socket.io-client'
import './main.css'
import { Elm } from './Main.elm'
import * as serviceWorker from './serviceWorker'

if (window.customElements) {
  window.customElements.define(
    'raw-html',
    class RawHtml extends HTMLElement {
      static get observedAttributes() {
        return ['content']
      }

      connectedCallback() {
        this.setContent()
      }

      attributeChangedCallback() {
        this.setContent()
      }

      setContent() {
        this.innerHTML = this.getAttribute('content') || ''
      }
    },
  )
}

const environment = process.env.NODE_ENV || 'development'

const apiHost =
  environment === 'production'
    ? 'songs-api.jeremyfairbank.com'
    : 'localhost:3001'

const apiProtocol = environment === 'production' ? 'https' : 'http'
const wsProtocol = environment === 'production' ? 'wss' : 'ws'

const apiUrl = `${apiProtocol}://${apiHost}`
const wsUrl = `${wsProtocol}://${apiHost}`

const app = Elm.Main.init({
  node: document.getElementById('root'),
  flags: {
    apiUrl,
    jwt: localStorage.getItem('jwt'),
    currentTime: Date.now(),
  },
})

let connectWS = () => {
  const socket = io(wsUrl)

  socket.on('message', data => {
    app.ports.wsReceive.send(data)
  })

  // Prevent creating duplicate connections
  connectWS = () => {}
}

app.ports.localStorageSet.subscribe(([key, value]) => {
  localStorage.setItem(key, value)
})

app.ports.wsListen.subscribe(() => {
  connectWS()
})

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
